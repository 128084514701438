import React from "react";
import nextbutton from "../images/next.svg"


interface ToolCardProp{
    title: string;
    content: string;
    svgContent: string;
    bgcolor?: string;
}
const ToolCard: React.FC<ToolCardProp> =({title, content, svgContent, bgcolor='bg-gradient-to-br from-aaipurple to-aaipurple-200'})=>{
    return(
        <div className={`cursor-pointer relative flex flex-col gap-8 p-12 ${bgcolor}  border border-white border-opacity-40 rounded-3xl`}>
            <div className='flex items-end gap-4'>
                    <h1 className="text-4xl font-medium">{title}</h1>
                    <img src={svgContent} alt=""></img>
                </div>
                <div className='flex gap-12 text-white text-opacity-60 text-medium leading-5'>
                    <p>{content}</p>
                    <img src={nextbutton} className="hidden sm:flex" alt=""></img>
                </div>
                <div className="absolute w-full h-full top-0 right-0 bg-transparent hover:bg-right-bottom hover:bg-black/20 rounded-3xl transition-all duration-100"></div>
            
        </div>
    );
};

export default ToolCard;