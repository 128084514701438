import React, { forwardRef } from 'react';
import ToolCard from './ToolCard';
import pentool from '../images/pen.svg';
import book from '../images/book.svg';
import lecture from '../images/lecturer.svg';
import upload from '../images/camera.svg';

const OtherTools = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <section ref={ref} className='flex bg-othertoolsbg pb-24 md:pb-40 pt-0 -mt-24'>
            <div className='flex flex-col max-w-7xl p-4 m-auto items-center'>
                <h1 className='text-4xl/tight md:text-5xl text-center font-semibold m-8 pb-8 md:m-16'>Explore our suite of tools.</h1>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                    <a href='https://answersai.com/essay-board'>
                        <ToolCard svgContent={pentool} title='Essay Mode' content='Essay writing made effortless. Input your prompt, then watch as AI helps you craft the perfect essay.' />
                    </a>
                    <a href='https://answersai.com/talk-to-book'>
                        <ToolCard svgContent={book} title='Talk to Book' content='Make your textbooks talk. Upload any PDF and ask away—every page becomes a conversation.' />
                    </a>
                    <a href='https://answersai.com/lecture/'>
                        <ToolCard svgContent={lecture} title='Lecture Mode' content='Master your lectures. Provide a link and instantly inquire deeper into any topic.' bgcolor='bg-gradient-to-r from-aaiblue to-aaiblue-200' />
                    </a>
                    <a href='https://chromewebstore.google.com/detail/answersai/gogdaelhkjfknmbphaopmmlehbafoifb?hl=en'>
                        <ToolCard svgContent={upload} title='Photo Solve' content='Get answers to your questions, your way. Snap a picture and let our AI solve it in seconds!' bgcolor='bg-gradient-to-r from-aaiblue to-aaiblue-200' />
                    </a>
                </div>
            </div>
        </section>
    );
});

export default OtherTools;
