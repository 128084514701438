import React from "react";
import facebook from '../images/Facebook.svg';
import tiktok from '../images/tiktok.svg';
import instagram from '../images/Instagram.svg';
import logo from '../images/logo.png';

const Footer: React.FC = () =>{
    return(
        <section className="flex flex-col footer-bg bg-cover p-16 md:p-24 md:pb-4 pb-4 font-redhat">
                <div className="flex flex-col sm:flex-row justify-between pb-8 footer-content">
                    <div className="footer-items">
                        <div className="flex items-end text-4xl font-bold mb-4 p-0 font-redhat">
                            <img src={logo} className='max-h-9 pr-2' alt=""></img>Answers<span style={{ color: '#6366F1' }}>Ai</span>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 pt-2 footer-nav text-gray-400">
                            <ul className="m-0 p-0 pt-3 list-none space-y-2">
                                <h2 className="text-xl font-semibold mb-4 text-white">Support</h2>
                                <li><a className="hover:text-white transition-all" href="https://answersai.com/faq" target="_blank" rel="noreferrer">Contact Us</a></li>
                                <li><a className="hover:text-white transition-all" href="https://answersai.com/faq" target="_blank" rel="noreferrer">FAQ</a></li>
                            </ul>
                            <ul className="m-0 p-0 pt-3 list-none space-y-2">
                                <h2 className="text-xl font-semibold mb-4 text-white">Solutions</h2>
                                <li><a className="hover:text-white transition-all" href="https://answersai.com" target="_blank" rel="noreferrer">For Students</a></li>
                                <li><a className="hover:text-white transition-all" href="https://answersai.com/institutions" target="_blank" rel="noreferrer">For Institutions</a></li>
                            </ul>
                            <ul className="m-0 p-0 pt-3 list-none space-y-2">
                                <h2 className="text-xl font-semibold mb-4 text-white">Legal</h2>
                                <li><a className="hover:text-white transition-all" href="https://answersai.com/privacy-policy" target="_blank" rel="noreferrer">Privacy</a></li>
                                <li><a className="hover:text-white transition-all" href="https://answersai.com/copyright-policy" target="_blank" rel="noreferrer">Copyright</a></li>
                                <li><a className="hover:text-white transition-all" href="https://answersai.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Service</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex grow pt-8 md:pt-0 md:justify-end max-y-16 items-start space-x-2">
                        <a href="https://www.facebook.com/profile.php?id=61555915947620" target="_blank" rel="noreferrer"><img className="hover:opacity-60 transition-all" src={facebook} alt="Facebook"/>
                        </a>
                        <a href="https://www.tiktok.com/@answersai.com" target="_blank" rel="noreferrer"><img className="hover:opacity-60 transition-all" src={tiktok} alt="Tiktok"/>
                        </a>
                        <a href="https://www.instagram.com/answersai/" target="_blank" rel="noreferrer"><img className="hover:opacity-60 transition-all" src={instagram} alt="Instagram"/>
                        </a>
                    </div>
                </div>
                <div className="flex flex-row md:justify-center text-justify md:text-center p-6 px-0 md:p-16 md:px-0 font-regular text-sm text-white text-opacity-60 footer-cc">
                © 2024 AnswersAi Corp. All Rights Reserved. AnswersAi, Corp. does not condone plagiarism or academic dishonesty. If our services have been misused, we reserve the right to take actions to maintain the integrity of our platform, including but not limited to co-operating with academic institutions and suspending accounts. 
            </div>
        </section>
    );
};

export default Footer;