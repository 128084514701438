import React from 'react';
/*import menubtn from '../images/menu.svg';*/
import logo from '../images/logo.png';

interface HeaderProps {
    scrollToTools: () => void;
}
const Header: React.FC<HeaderProps> = ({scrollToTools}) => {
    /*const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };*/
    return (
        <header className="flex justify-center md:justify-between items-center p-10 px-12 2xl:p-16">
            <div className="flex items-end text-3xl 2xl:text-4xl font-bold font-redhat">
                <img src={logo} className='max-h-8 px-2' alt=''></img>Answers<span style={{ color: '#6366F1' }}>Ai</span>
            </div>
            <button onClick={scrollToTools} className='hover:bg-white hover:bg-opacity-10 cursor-pointer hidden lg:flex 2xl:text-xl 2xl:p-4 2xl:px-12 2xl:shadow-ctawhite-200 p-2 px-8 rounded-full border-gray-500 border-2 shadow-ctawhite transition-all' > Other Tools </button>
            {/*TODO @adilsanad: Reactivate these comments when we have these pages.*/}
            {/*
                <div className='hidden lg:flex space-x-10 font-redhat font-medium 2xl:text-xl text-white text-opacity-60'>
                    <a>About</a>
                    <a>Pricing</a>
                    <a>Learners</a>
                    <a>Contact</a>
                </div>
                <a className='hidden lg:flex 2xl:text-xl 2xl:p-4 2xl:px-12 2xl:shadow-ctawhite-200 p-2 px-8 rounded-full border-gray-500 border-2 shadow-ctawhite'>
                    <p>Logout</p>
                </a>
                <button onClick={toggleDropdown} className='lg:hidden'>
                    <img src={menubtn}></img>
                </button>
                {isOpen &&
                <div
                className={`absolute top-16 right-0 w-full bg-[#0E0F13] text-white flex flex-col items-center space-y-4 p-4 lg:hidden overflow-hidden transition-all duration-300 ${
                    isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
                }`}
                style={{ transitionTimingFunction: 'ease-in-out' }}>
                    <a href="#about" className="w-full text-right py-2 ">About</a>
                    <a href="#pricing" className="w-full text-right py-2 ">Pricing</a>
                    <a href="#learners" className="w-full text-right py-2 ">Learners</a>
                    <a href="#contact" className="w-full text-right py-2 ">Contact</a>
                    <a href="#logout" className="w-full text-right py-2 rounded-full">Logout</a>
                </div>
            }*/}
        </header>
    );
};

export default Header;