import React, { useRef } from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Explainer from './components/Explainer';
import OtherTools from './components/OtherTools';
import Footer from './components/Footer';

function App() {
    const otherToolsRef = useRef<HTMLDivElement>(null);

    const scrollToTools = () => {
        if(otherToolsRef.current){
            otherToolsRef.current.scrollIntoView({behavior: 'smooth'});
        }
    };
	return (
		<div className='flex flex-col overflow-hidden bg-gradient'>
            <Header scrollToTools={scrollToTools}/>
            <Hero />
            <Explainer />
            <OtherTools ref={otherToolsRef}/>
            <Footer />
        </div>
	);
}

export default App;
