'use client';
import React, { useState } from 'react';
import downloadicon from '../images/downloadicon.svg';

const TextToVideoForm: React.FC = () => {
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [truncatedTitle, setTruncatedTitle] = useState('Prompt Title');
    const [likedislike, setLikeDislike] = useState<'like'|'dislike'|''>('');

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const text = (event.target as any).text.value;
        setTruncatedTitle(text.length > 30 ? text.substring(0,30) + '...' : text);

        setVideoUrl(null);
        setLoading(true);
        setProgress(0);

        const progressInterval = setInterval(() => {
            setProgress((prev) => (prev < 90 ? prev + 1 : prev));
        }, 1200);

        try {
            const response = await fetch('http://3.141.212.78:5000/create-video', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text })
            });

            const data = await response.json();

            setTimeout(() => {
                setProgress(100);
                setTimeout(() => {
                    setVideoUrl(data.url);
                    setLoading(false);
                    clearInterval(progressInterval);
                }, 500); 
            }, 1000); 
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            clearInterval(progressInterval);
        }
    };

    console.log(likedislike);
    console.log(progress);

    return (
        <div className='flex flex-col justify-center box-border max-w-[1440px] w-full font-redhat p-8 md:p-20 pt-4 '>
            <div className='w-full backdrop-blur-md bg-[#6366F1] bg-videotoolbg bg-contain rounded-[48px] border-2 border-[#FFFFFF] border-opacity-40 shadow-cardwhite'>
                <div className='p-4 pb-8 sm:p-8'>
                    <form onSubmit={handleSubmit} className='max-w-full mx-auto'>
                        <div className="flex flex-col">
                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 justify-center sm:justify-between items-end p-6 pt-8'>
                                <label htmlFor="text" className="flex text-center md:text-left mb-2 text-3xl font-medium text-white">Ask me anything.</label>
                                <input type="submit" value="Generate" className="cursor-pointer shadow-ctablack justify-self-end grow w-full sm:max-w-[150px] px-8 py-3 bg-white text-black font-semibold rounded-full hover:bg-gray-300 focus:outline-none transition-all" />
                            </div>
                            <textarea id="text" name="text" placeholder="Describe any topic or ask any question here" rows={8} cols={50} required className="focus:outline-none p-8 w-full text-lg rounded-xl border-2 bg-[#5157B1] border-gray-600 placeholder-gray-400 text-white focus:border-aaipurple-800"></textarea>
                        </div>
                    </form>
                </div>

                {loading && 
                <div id="loading" className='box-border -mb-2 flex flex-col w-full outline outline-2 outline-[#A1A3F7] rounded-3xl overflow-hidden bg-[#2F3169]'>
                    <div className='flex justify-between items-end p-8 pb-4'>
                        <div className=''>
                            <p className='text-4xl font-bold pr-4'>{progress}%</p>
                        </div>
                        <div className='flex flex-col sm:flex-row items-end text-end text-md/tight space-x-2'>
                            <p className='leading-5'>{progress < 90 ? "Your video is being generated" : "Nearly done, please wait"}</p>
                            <div className='flex space-x-[4px] pt-2'>
                                <div className='h-[6px] w-[6px] bg-[#6366F1] rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                                <div className='h-[6px] w-[6px] bg-[#6366F1] rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                                <div className='h-[6px] w-[6px] bg-[#6366F1] rounded-full animate-bounce'></div>
                            </div>
                        </div>
                    </div>
                    <div className='rounded-full bg-white bg-opacity-20 h-2 m-8 mt-0'>
                        <div className='rounded-full bg-white h-full transition-all' style={{ width: `${progress}%` }}>
                        </div>
                    </div>
                </div>
                }

                {videoUrl && 
                <div className=' box-border -mb-2 flex flex-col w-full outline outline-2 outline-[#A1A3F7] rounded-[48px] rounded-b-[24px] overflow-hidden bg-[#2F3169]'>
                    <div className='flex flex-col items-center md:flex-row gap-4 md:justify-between p-8 items-end'>
                        <h2 className='gap-1 text-2xl text-center md:text-left lg:text-3xl font-regular px-4'>Prompt title · <span className='text-gray-400'>{truncatedTitle}</span></h2>
                        <div className='z-30 shrink-0 flex gap-4'>
                            <div className='cursor-pointer text-transparent flex gap-2 bg-aaipurple-800 hover:bg-opacity-60 border-2 border-aaipurple-400 border-opacity-40 hover:border-white hover:border-opacity-30 justify-center items-center rounded-full p-3 transition-all'>
                                <button className={`${likedislike === 'dislike' ? 'text-red-500': ''}`} onClick={()=>setLikeDislike('dislike')}>
                                    <svg className='px-[4px] w-full hover:text-red-500 transition-all' width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.6667 10.8333L11.3334 18.3333C10.6703 18.3333 10.0344 18.07 9.56559 17.6011C9.09675 17.1323 8.83336 16.4964 8.83336 15.8333V12.5H4.11669C3.8751 12.5028 3.63581 12.4529 3.41538 12.354C3.19496 12.2551 2.99868 12.1094 2.84014 11.9271C2.68161 11.7448 2.5646 11.5302 2.49724 11.2981C2.42988 11.0661 2.41377 10.8222 2.45003 10.5833L3.60003 3.08335C3.66029 2.68593 3.86216 2.32368 4.16844 2.06334C4.47471 1.80301 4.86475 1.66214 5.26669 1.66668H14.6667M14.6667 10.8333V1.66668M14.6667 10.8333H16.8917C17.3633 10.8417 17.8216 10.6766 18.1795 10.3693C18.5375 10.0621 18.7701 9.63413 18.8334 9.16668V3.33335C18.7701 2.8659 18.5375 2.43793 18.1795 2.1307C17.8216 1.82346 17.3633 1.65834 16.8917 1.66668H14.6667" stroke="white" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button><span className='text-white'>|</span>
                                <button className={`${likedislike === 'like' ? 'text-green-500' : ''}`} onClick={()=>setLikeDislike('like')}>
                                    <svg className='px-[4px] w-full hover:text-green-500 transition-all' width="19" height="20" viewBox="0 0 19 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.33329 9.16663L8.66663 1.66663C9.32967 1.66663 9.96555 1.93002 10.4344 2.39886C10.9032 2.8677 11.1666 3.50358 11.1666 4.16663V7.49996H15.8833C16.1249 7.49722 16.3642 7.54705 16.5846 7.64597C16.805 7.7449 17.0013 7.89057 17.1598 8.07289C17.3184 8.2552 17.4354 8.46981 17.5027 8.70184C17.5701 8.93386 17.5862 9.17776 17.55 9.41663L16.4 16.9166C16.3397 17.314 16.1378 17.6763 15.8315 17.9366C15.5253 18.197 15.1352 18.3378 14.7333 18.3333H5.33329M5.33329 9.16663V18.3333M5.33329 9.16663H2.83329C2.39127 9.16663 1.96734 9.34222 1.65478 9.65478C1.34222 9.96734 1.16663 10.3913 1.16663 10.8333V16.6666C1.16663 17.1087 1.34222 17.5326 1.65478 17.8451C1.96734 18.1577 2.39127 18.3333 2.83329 18.3333H5.33329" stroke="white" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <button
                            className="cursor-pointer flex bg-aaipurple-800 hover:bg-opacity-60 border-2 border-aaipurple-400 border-opacity-40 hover:border-white hover:border-opacity-30 justify-center items-center rounded-full p-3 transition-all"
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = videoUrl || '#';
                                link.download = 'generated-video.mp4';
                                link.click();
                            }}
                            >
                                <img src={downloadicon} className='px-[4px]' alt="Download"></img>
                            </button>
                        </div>
                    </div>
                    <video id="resultVideo" controls width="100%" src={videoUrl} className='rounded-3xl overflow-hidden'>
                        Your browser does not support the video tag.
                    </video>
                </div>
                }
            </div>
        </div>
    );
};

export default TextToVideoForm;
