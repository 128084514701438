import React from 'react';
import exampleprompts from '../images/examples.svg';

const Explainer: React.FC = () => {
    return (
        <section className='overflow-show w-full pb-24 bg-examplesbg bg-cover bg-no-repeat bg-left pt-24 md:pt-48 xl:pt-72 md:pb-96'>
             <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-20 2xl:gap-4'>
                <img className='object-cover w-full 2xl:w-5/6 min-w-[400px]' src={exampleprompts} alt=''></img>
                <div className='lg:max-w-lg 2xl:max-w-2xl flex flex-col text-center lg:text-left sm:p-8 p-8 gap-10'>
                    <h1 className='text-4xl/tight md:text-5xl/tight 2xl:text-6xl/tight font-semibold'>Sometimes you just need someone to explain it to you.</h1>
                    <p className='text-md md:text-lg 2xl:text-2xl font-regular text-white text-opacity-40'>Unlock the power of AI to transform your text into engaging video lectures. Whether it's a complex theory, historical event, or scientific concept, our models simplify it and bring it to life visually. After all, nothing beats having a personal teacher to explain all your doubts away.</p>
                </div>
             </div>
        </section>
    );
};

export default Explainer;