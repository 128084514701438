import TextToVideoForm from './TextToVideoForm';

const Hero: React.FC = () => {
    return (
        <section className='flex flex-col items-center md:pt-24 md:pb-36 bg-herobg bg-contain bg-right bg-no-repeat'>
            <div className='flex flex-col items-center space-y-8 mt-16 p-4 sm:p-8 pt-4'>
                <h1 className='text-4xl sm:text-5xl md:text-6xl font-bold max-w-3xl text-center leading-tight'>Turn any question into a video lecture.</h1>
                <p className='text-white text-opacity-60 text-lg sm:text-xl w-3/4 text-center font-regular pb-24'>Generate personalized lectures on any topic, anytime within minutes.
                </p>
            </div>
            <TextToVideoForm />
        </section>
    );
  }
  
  export default Hero;
  